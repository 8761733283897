<template>
    <!--components/CountTable.vue-->
    <table class="count-table">
        <tbody>
            <tr>
                <td>
                    <div class="clickable" @click="download_task_from_community" v-if="(task_ref.valueOf().hasOwnProperty('from_community')) && (task_ref.valueOf()['from_community'] === true)">
                        <span class="class_community_indicator">community</span><br/>
                        <i class="fa-light fa-download"></i>
                    </div>
                     <div v-else>
                        <span class="class_no_link class_community_indicator">community</span><br/>
                        <i class="class_no_link fa-light fa-download"></i>                        
                    </div>
                </td>

                <td>
                    <i :class="{'fa-duotone fa-lg fa-thumbs-up': true, 'clickable': true, 'voted': voted.valueOf()}" @click.prevent.stop="upvote_handler"></i><br/>
                    {{ task_ref.valueOf().num_voters || 0 }}
                </td>
                <td> 
                    <div v-if="(task_ref.valueOf().parent_tasks) && (task_ref.valueOf().parent_tasks.length > 0)">
                        <!-- Community linkto URL -->
                        <NuxtLink 
                            v-if="((task_ref.valueOf().hasOwnProperty('from_community')) && (task_ref.valueOf()['from_community'] === true))" 
                            :to="community_url + '/?linkto=' + `${task_ref.valueOf().id}`" 
                            class="text-nowrap" 
                            no-prefetch 
                            target="_blank"
                            :disabled="(is_client_side) &&(! page_mounted_state)"
                            :class="{'disabled':  (is_client_side) &&(! page_mounted_state)}" 
                        ><i class="fa-duotone fa-lg fa-link-simple"></i></NuxtLink>

                        <!-- Local instance linkto URL -->
                        <NuxtLink 
                            v-if="(! ((task_ref.valueOf().hasOwnProperty('from_community')) && (task_ref.valueOf()['from_community'] === true)))"  
                            :to="'/?linkto=' + `${task_ref.valueOf().id}`" 
                            class="text-nowrap" 
                            no-prefetch
                            :disabled="(is_client_side) &&(! page_mounted_state)"
                            :class="{'disabled':  (is_client_side) &&(! page_mounted_state)}" 
                        ><i class="fa-duotone fa-lg fa-link-simple"></i></NuxtLink>
                        <br/>
                        {{ task_ref.valueOf().parent_tasks.length}}
                    </div>
                     <div v-else>
                        <i class=" class_no_link fa-duotone fa-lg fa-link-simple"></i>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script setup>
    import { downloadTask } from '~/services/taskService'
    const props = defineProps({
        item: {
            type: Object
        }
    });
    const is_client_side = process.client;
    const page_mounted_state = usePageMountedState();

    let task_ref = ref(props.item);
    let voted = ref(false);


const upvote_handler = async (event) => {
    const authState = useAuthState();
    if (!authState.value) {
        dkAlert("Please sign-in in order to vote.");
        return;
    }
    /*
    Here we add the prevent_interaction_until_mouseover class, but that alone does not seems to work, because 
    when we assigned the updated_task variable to task_ref.value to get the UI to re-render (to reflect the 
    correct vote count, whether we have upvote or downvoted), this re-rendering is removing the 
    prevent_interaction_until_mouseover class from the element, so we have to permanently add this class 
    to the element, and temorarily remove it with the help of the mouseover handler and the mouseout handler.
    We have to do this to prevent users from accidentally double-clicking on the element.  So we do not really 
    need to add or remove the prevent_interaction_until_mouseover here.  But having prevent_interaction_until_mouseover
    added to the element prevents the mouseover, mouseenter event from working.
    */

    event.target.classList.add('prevent_interaction');
    await showWaitPage();
    let res = await upvote(task_ref.value.id);
    let updated_task = await readTask(task_ref.value.id,{})
    hideWaitPage();

    if (voted.value === false) {
        voted.value = true;
    } else {
        voted.value = false;
    }
    task_ref.value = updated_task;
}

const download_task_from_community = async () => {
    await showWaitPage();
    let new_task = await downloadTask(task_ref.value.id);
    hideWaitPage();

    dkAlert("Task downloaded.  You will now be redirected to the page for the downloaded task.", function(){
        dkNavigateTo(`/tasks/${new_task.id}`);
    });
    
    /*
    alert("Task downloaded.  You will now be redirected to the page for the downloaded task.");
    dkNavigateTo(`/tasks/${new_task.id}`);
    */
}

let community_url = getCommunityURL();


onMounted(() => {
    let uid = 'anonymous'

    if ((process.client) && (localStorage.getItem('isAuthenticated') == '1')) {
        uid = localStorage.getItem('uid');
    }
    if ((task_ref.value.hasOwnProperty('voters')) && (task_ref.value.voters.hasOwnProperty(uid))) {
        voted.value = true;
    }
})

</script>

<style scoped>
    .count-table td {
        width: 40px;
        text-align: center;
        padding-right: 15px;
        padding-left: 15px;
    }
    .class_community_indicator {
        font-size: 10px;
        border: 1px solid orange;
        border-radius: 5px;
        padding-left: 3px;
        padding-right: 3px;
        padding-top: 2px;
        background: #fffad7;
    }
    .class_no_link{
        visibility: hidden;
    }
</style>
